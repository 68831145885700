// src/pages/ProduceSection.js
import React, { useContext } from 'react';
import './ProductSection.css';
import { Link } from 'react-router-dom';

import { LanguageContext } from '../context/LanguageContext';
import Herobg from '../assets/images/Herobg.jpg';

// Import your icons
import blueberryIcon from '../assets/icons/icons/Berries.png';
import teaIcon from '../assets/icons/icons/Tea.png';
import hazelnutIcon from '../assets/icons/icons/Hazelnut.png';
import honeyIcon from '../assets/icons/icons/Honey.png';
import solarIcon from '../assets/icons/icons/Solar.png';


function ProduceSection() {
  
  const {t} = useContext(LanguageContext);

  return (
    <section className="produce-section">
      <div class="parent">
          <Link to='/blueberry' className="div1">
              <img height="49" width="49" src={blueberryIcon} alt=""/>
              <span>{t.Home.Products.blueberry} </span>
          </Link>
          <Link to='/honey' className="div2">
              <img height="49" width="49" src={honeyIcon} alt=""/>
              <span>{t.Home.Products.honey} </span>
          </Link>
          <Link to='/solarpanels' className="div3">
              <img height="49" width="49" src={solarIcon} alt=""/>
              <span>{t.Home.Products.solar} </span>
          </Link>
          <Link to='/hazelnut' className="div4">
              <img height="49" width="49" src={hazelnutIcon} alt=""/>
              <span>{t.Home.Products.hazelnut} </span>
          </Link>
          <div class="div5">
          </div>
          <div class="div6">
          </div>
          <Link to='tea' className="div7">
              <img height="49" width="49" src={teaIcon} alt=""/>
              <span>{t.Home.Products.tea} </span>
          </Link>
          
      </div>


      <div className="produce-card">
        <div className="card-content">
          <h2>{t.Home.Products.ProductsTitle}</h2>
          <p>
            {t.Home.Products.ProductsText}
          </p>
          <img src={blueberryIcon} alt="BlueBerry" className="card-icon" />
        </div>
      </div>
    </section>
  );
}

export default ProduceSection;