// src/pages/Contact.js
import React, {useContext} from 'react';
import { LanguageContext } from '../context/LanguageContext';
import './Contact.css';
import Navbar from '../components/Navbar';


// Example icons from react-icons (optional)
import { MdEmail } from 'react-icons/md';
import { FaPhone, FaInstagram, FaFacebook } from 'react-icons/fa';

function Contact() {
  const {t} = useContext(LanguageContext);
  return (
    <>
    <div><Navbar /></div>
    <div className="contact-container">
      <h1 className="contact-title">{t.Contact.title}</h1>

      <div className="contact-details">
        <p>{t.Contact.company}: LTD Seeder</p>
        <p>{t.Contact.legaladdr}</p>
        <p>{t.Contact.addr}</p>
        <p>{t.Contact.Director}</p>
      </div>
      
      {/* Google Maps Embed */}
      <div className="contact-map">
        <h2 className="map-title">Find Us</h2>
        <div className="map-container">
          <iframe 
            title="Our Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5937.564082914746!2d41.90500122672515!3d41.919043993691865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x405d750022bf9a8d%3A0x8b92c8110eec1b05!2sSEEDER%20Blueberry%20Farm%20%7C%20Tea%20%7C%20H%20Nuts%20%7C%20Honey!5e0!3m2!1sen!2sge!4v1741694947455!5m2!1sen!2sge"
            width="100%" 
            height="500vh" 
            style={{border:0}} 
            allowFullScreen="" 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade">
          </iframe>
        </div>
      </div>

      <div className="contact-info-section">
        <div className="contact-info-item">
          {/* Icon (optional) */}
          <MdEmail className="contact-icon" />
          <div className="contact-info-text">
            <a href="mailto:laituriagri@gmail.com">laituriagri@gmail.com</a>
            <a href="mailto:rkhargelia@gmail.com">rkhargelia@gmail.com</a>
          </div>
        </div>

        <div className="contact-info-item">
          <FaPhone className="contact-icon" />
          <span>+995 599 177 774</span>
        </div>
      </div>

      <div className="contact-social">
        <p>Follow us:</p>
        <a href="https://www.facebook.com/profile.php?id=100083196437437" target="_blank" rel="noreferrer">
          <FaFacebook className="social-icon" />
          Facebook
        </a>
      </div>
    </div>
    </>
  );
}

export default Contact;