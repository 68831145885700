// src/pages/MissionSection.js
import React, { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import './MissionSection.css';


import Berry from '../assets/images/Blueberry.jpg';
import Tea from '../assets/images/Tea.jpg';
import Solar from '../assets/images/solarPanels.jpg';
import Bees from '../assets/images/blueberryworth.jpg';

function MissionSection() {
  const { t } = useContext(LanguageContext);

  return (
    <div className="mission-section">
      {/* LEFT: Text content */}
      <div className="mission-text">
        <h2>{t.Mission.title}</h2>
        <h3>{t.Mission.partitle}</h3>
        <p>{t.Mission.text}</p>
        <h3>{t.Mission.partitle2}</h3>
        <p>{t.Mission.text2}</p>
      </div>

      {/* RIGHT: 2x2 images grid with custom sizing */}
      <div className="mission-images-grid">
        {/* Top Row */}
        <img src={Berry} alt="Blueberry" className="img1" />
        <img src={Tea} alt="Tea Field" className="img2" />

        {/* Bottom Row */}
        <img src={Solar} alt="Solar Panels" className="img3" />
        <img src={Bees} alt="Honey Bee" className="img4" />
      </div>
    </div>
  );
}

export default MissionSection;
