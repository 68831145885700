import React, { useContext } from 'react';
import './SolarPanels.css';
import Navbar from '../components/Navbar';
import { LanguageContext } from '../context/LanguageContext';

// Example images (replace with your own paths)
import img1 from '../assets/images/Blueberry.jpg';
import img2 from '../assets/images/blueberryworth.jpg';
import img3 from '../assets/images/solarPanels.jpg';
import img4 from '../assets/images/Tea.jpg';

// Example icons for the produce row
import iconHazelnut from '../assets/icons/hazelnut_black.png';
import iconHoney from '../assets/icons/honey_black.png';
import iconSolar from '../assets/icons/solar-panels_black.png';
import iconTea from '../assets/icons/tea_black.png';
import iconBlueberry from '../assets/icons/berries_black.png';
import iconCold from '../assets/icons/cold_black.png';
import { Link } from 'react-router-dom';

function Solar() {
  const { t } = useContext(LanguageContext);

  return (
    <>
      <div>
        <Navbar />
        <div className="Solar-container"></div>

        <div className="Solar-content">
          <h1 className="Solar-title">{t.SolarPanels.title}</h1>
          
          <div className="Solar-info">
            <div className="Solar-text">
              <h2>{t.SolarPanels.sustainableAgriculture}</h2>
              <p>{t.SolarPanels.sustainableAgricultureText}</p>

              <h2>{t.SolarPanels.cultivationStrategy}</h2>
              <p>{t.SolarPanels.cultivationStrategyText}</p>
            </div>

            <div className="Solar-images">
              <img src={img1} alt="Blueberry 1" />
              <img src={img2} alt="Blueberry 2" />
              <img src={img3} alt="Blueberry 3" />
              <img src={img4} alt="Blueberry 4" />
            </div>
          </div>
        </div>

        <div className="Solar-other-produces">
          <h3>{t.SolarPanels.otherProducts}</h3>
          <div className="other-produces-list">            
            <Link to='/blueberry' className="produce-item">
              <img src={iconBlueberry} alt="iconBlueberry" />
              <span>{t.SolarPanels.blueberry} →</span>
            </Link>
            <Link to='/hazelnut' className="produce-item">
              <img src={iconHazelnut} alt="icon Hazelnut" />
              <span>{t.SolarPanels.hazelnut} →</span>
            </Link>
            <Link to='/honey' className="produce-item">
              <img src={iconHoney} alt="Honey" />
              <span>{t.SolarPanels.honey} →</span>
            </Link>
            <Link to='/tea' className="produce-item">
              <img src={iconTea} alt="Tea" />
              <span>{t.SolarPanels.tea} →</span>
            </Link>
            <Link to='/cold' className="produce-item">
              <img src={iconCold} alt="iconCold" />
              <span>{t.SolarPanels.cold} →</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Solar;
