import React, { useState } from 'react';
import './News.css';
import Navbar from '../components/Navbar';


import honeyImg from '../assets/images/blueberryworth.jpg';
import blueberryImg from '../assets/images/Blueberry.jpg';
import teaImg from '../assets/images/Tea.jpg';

// We'll create some sample data for demonstration
const newsItems = [
  {
    id: 1,
    title: 'Premium Honey Harvest Begins 🍯',
    shortText:'This season’s blueberry harvest is underway, bringing in fresh, juicy, and high-quality berries!',
    fullText: 'This season’s blueberry harvest is underway, bringing in fresh, juicy, and high-quality berries! Thanks to careful cultivation...',
    image: honeyImg,
  },
  {
    id: 2,
    title: 'Blueberry Harvest in Full Swing 🫐',
    shortText:'This season’s blueberry harvest is underway, bringing in fresh, juicy, and high-quality berries!',
    fullText: 'This season’s blueberry harvest is underway, bringing in fresh, juicy, and high-quality berries! Thanks to careful cultivation...',
    image: blueberryImg,
  },
  {
    id: 3,
    title: 'Fresh Black Tea Leaves Harvested 🍵',
    shortText:'This season’s blueberry harvest is underway, bringing in fresh, juicy, and high-quality berries!',
    fullText: 'This season’s blueberry harvest is underway, bringing in fresh, juicy, and high-quality berries! Thanks to careful cultivation...',
    image: teaImg,
  },
  // Add more items if you like; we'll just repeat these for a total of 6
  {
    id: 4,
    title: 'Premium Honey Harvest Begins 🍯',
    shortText:'This season’s blueberry harvest is underway, bringing in fresh, juicy, and high-quality berries!',
    fullText: 'This season’s blueberry harvest is underway, bringing in fresh, juicy, and high-quality berries! Thanks to careful cultivation...',
    image: honeyImg,
  },
  {
    id: 5,
    title: 'Blueberry Harvest in Full Swing 🫐',
    shortText:'This season’s blueberry harvest is underway, bringing in fresh, juicy, and high-quality berries!',
    fullText: 'This season’s blueberry harvest is underway, bringing in fresh, juicy, and high-quality berries! Thanks to careful cultivation...',
    image: blueberryImg,
  },
  {
    id: 6,
    title: 'Fresh Black Tea Leaves Harvested 🍵',
    shortText:'This season’s blueberry harvest is underway, bringing in fresh, juicy, and high-quality berries!',
    fullText: 'This season’s blueberry harvest is underway, bringing in fresh, juicy, and high-quality berries! Thanks to careful cultivation...',
    image: teaImg,
  },
];


function News() {
  // State for visible news items with pagination
  const [visibleCount, setVisibleCount] = useState(3);
  
  // State to track expanded cards
  const [expandedId, setExpandedId] = useState(null);
  
  // Get current visible items
  const visibleItems = newsItems.slice(0, visibleCount);
  
  // Check if there are more items to load
  const hasMoreItems = visibleCount < newsItems.length;
  
  // Handle "More news" button click
  const handleLoadMore = () => {
    setVisibleCount(prevCount => prevCount + 3);
  };
  
  // Handle card expansion
  const handleCardClick = (id) => {
    // If clicking the same card, collapse it
    if (expandedId === id) {
      setExpandedId(null);
    } else {
      // Otherwise expand the clicked card
      setExpandedId(id);
    }
  };

  return (
    <>
      <div><Navbar /></div>
      <div className="news-container">
        {/* Heading */}
        <h1 className="news-title">News</h1>
        <p className="news-subtitle">
          Latest Updates &amp; Announcements – Stay informed with the newest developments.
        </p>

        {/* News grid */}
        <div className="news-grid">
          {visibleItems.map((item) => (
            <div 
              className={`news-card ${expandedId === item.id ? 'expanded' : ''}`} 
              key={item.id}
            >
              <div className="news-image">
                <img src={item.image} alt={item.title} />
              </div>
              <div className="news-content">
                <h2>{item.title}</h2>
                <p>{expandedId === item.id ? item.fullText : item.shortText}</p>
                <button 
                  className="news-continue" 
                  onClick={() => handleCardClick(item.id)}
                >
                  {expandedId === item.id ? 'Show less ↑' : 'Continue reading →'}
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* "More news" button - only shown if there are more items */}
        {hasMoreItems && (
          <div className="news-load-more">
            <button onClick={handleLoadMore}>More news</button>
          </div>
        )}
      </div>
    </>
  );
}

export default News;
