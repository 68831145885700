import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../context/LanguageContext';
import './Navbar.css';
import logo from '../assets/Logo.png';

function Navbar() {
  const { language, setLanguage, t } = useContext(LanguageContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isProductsDropdownOpen, setIsProductsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Handle window resize to detect mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      // Close the products dropdown when switching from mobile to desktop
      if (window.innerWidth > 768) {
        setIsProductsDropdownOpen(false);
      }
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    // Prevent scrolling when menu is open
    document.body.style.overflow = !isMobileMenuOpen ? 'hidden' : 'auto';
  };

  // Handle link clicks on mobile - close menu and enable scrolling
  const handleMobileNavClick = () => {
    if (isMobile) {
      setIsMobileMenuOpen(false);
      document.body.style.overflow = 'auto'; // Re-enable scrolling
    }
  };

  // Toggle products dropdown - only for mobile
  const toggleProductsDropdown = (e) => {
    if (isMobile) {
      e.preventDefault();
      e.stopPropagation();
      setIsProductsDropdownOpen(!isProductsDropdownOpen);
    }
  };

  // Close mobile menu and dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close mobile menu when clicking outside
      if (isMobileMenuOpen && 
          !event.target.closest('.navbar-container') && 
          !event.target.closest('.navbar-links') && 
          !event.target.closest('.mobile-menu-btn')) {
        setIsMobileMenuOpen(false);
        document.body.style.overflow = 'auto'; // Make sure to re-enable scrolling
      }
      
      // Close dropdown when clicking outside (only for mobile)
      if (isMobile && isProductsDropdownOpen && 
          !event.target.closest('.dropdown') &&
          !event.target.closest('.dropdown-toggle')) {
        setIsProductsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen, isProductsDropdownOpen, isMobile]);

  return (
    <header className="navbar-header">
      <nav className="navbar-container">
        {/* Logo */}
        <div className="navbar-logo">
          <Link to="/" onClick={handleMobileNavClick}>
            <img src={logo} alt="Seeder Logo" />
          </Link>
        </div>

        {/* Mobile Menu Button (only on mobile) */}
        {isMobile && (
          <button className="mobile-menu-btn" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? '✕' : '☰'}
          </button>
        )}

        {/* Navigation Links */}
        <ul className={`navbar-links ${isMobile && isMobileMenuOpen ? 'open' : ''}`}>
          <li><Link to="/" onClick={handleMobileNavClick}>{t.Nav.Home}</Link></li>
          
          {/* Dropdown for Products/Our Produce */}
          <li className="dropdown">
            {isMobile ? (
              // Mobile: Toggle dropdown on click
              <>
                <span className="dropdown-toggle" onClick={toggleProductsDropdown}>
                  {t.Nav.Products} <span className="dropdown-arrow">&#x25BE;</span>
                </span>
                {isProductsDropdownOpen && (
                  <ul className="dropdown-menu dropdown-menu-visible">
                    <li><Link to="/blueberry" onClick={handleMobileNavClick}>{t.Nav.Blueberry}</Link></li>
                    <li><Link to="/hazelnut" onClick={handleMobileNavClick}>{t.Nav.Hazelnut}</Link></li>
                    <li><Link to="/honey" onClick={handleMobileNavClick}>{t.Nav.Honey}</Link></li>
                    <li><Link to="/tea" onClick={handleMobileNavClick}>{t.Nav.Tea}</Link></li>
                    <li><Link to="/solarpanels" onClick={handleMobileNavClick}>{t.Nav.Solar}</Link></li>
                    <li><Link to="/cold" onClick={handleMobileNavClick}>{t.Nav.Cold}</Link></li>
                  </ul>
                )}
              </>
            ) : (
              // Desktop: Use hover behavior - no click event needed
              <>
                <span className="dropdown-toggle">
                  {t.Nav.Products} <span className="dropdown-arrow">&#x25BE;</span>
                </span>
                <ul className="dropdown-menu">
                  <li><Link to="/blueberry" onClick={handleMobileNavClick}>{t.Nav.Blueberry}</Link></li>
                  <li><Link to="/hazelnut" onClick={handleMobileNavClick}>{t.Nav.Hazelnut}</Link></li>
                  <li><Link to="/honey" onClick={handleMobileNavClick}>{t.Nav.Honey}</Link></li>
                  <li><Link to="/tea" onClick={handleMobileNavClick}>{t.Nav.Tea}</Link></li>
                  <li><Link to="/solarpanels" onClick={handleMobileNavClick}>{t.Nav.Solar}</Link></li>
                  <li><Link to="/cold" onClick={handleMobileNavClick}>{t.Nav.Cold}</Link></li>
                </ul>
              </>
            )}
          </li>
          
          <li><Link to="/news" onClick={handleMobileNavClick}>{t.Nav.News}</Link></li>
          <li><Link to="/gallery" onClick={handleMobileNavClick}>{t.Nav.Gallery}</Link></li>
          <li><Link to="/contact" onClick={handleMobileNavClick}>{t.Nav.Contact}</Link></li>
          
          <li><Link to="/partners" onClick={handleMobileNavClick}>{t.Nav.Partners}</Link></li>
          
          {/* Mobile Language Selector */}
          {isMobile && (
            <div className="mobile-navbar-lang">
              <div className="mobile-lang-dropdown-content">
                <button 
                  onClick={() => {
                    setLanguage('en');
                    handleMobileNavClick();
                  }} 
                  className={language === 'en' ? 'active' : ''}
                >
                  ENG
                </button>
                <button 
                  onClick={() => {
                    setLanguage('ge');
                    handleMobileNavClick();
                  }} 
                  className={language === 'ge' ? 'active' : ''}
                >
                  GEO
                </button>
              </div>
            </div>
          )}
        </ul>
        
        {/* Desktop Language Selector */}
        {!isMobile && (
          <div className="navbar-lang">
            <div className="lang-dropdown">
              <button className="lang-btn">
                {language === 'en' ? <p>ENG</p> : <p>GEO</p>}
              </button>
              <div className="lang-dropdown-content">
                <button onClick={() => setLanguage('en')} className={language === 'en' ? 'active' : ''}>
                  ENG
                </button>
                <button onClick={() => setLanguage('ge')} className={language === 'ge' ? 'active' : ''}>
                  GEO
                </button>
              </div>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
}

export default Navbar;