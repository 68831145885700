// src/context/LanguageContext.js
import React, { createContext, useState, useMemo } from 'react';

// Import your JSON language files
import en from './lang/eng.json';
import ge from './lang/ge.json';

export const LanguageContext = createContext({ t: { Text: "something"} });


// Map language codes to their JSON data
const translations = {
  en,
  ge
};

export function LanguageProvider({ children }) {
  const [language, setLanguage] = useState('en');

  const languageData = useMemo(() => {
    return translations[language] || translations.en;
  }, [language]);

  const value = {
    language,       
    setLanguage,    
    t: languageData // use 't' for translations
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
}
