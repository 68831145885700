// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { LanguageProvider } from './context/LanguageContext';
import Footer from './components/Footer';

import Home from './pages/Home';
import Blueberry from './pages/Blueberry';
import Hazelnut from './pages/Hazelnut';
import Honey from './pages/Honey';
import Tea from './pages/Tea';
import SolarPanels from './pages/SolarPanels';
import News from './pages/News';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import Cold from './pages/Cold';
import Partners from './pages/partners';

function App() {
  return (
    <LanguageProvider>
      <Router>
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          
          
          <div style={{ flex: 1, padding: '0' }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/blueberry" element={<Blueberry />} />
              <Route path="/hazelnut" element={<Hazelnut />} />
              <Route path="/honey" element={<Honey />} />
              <Route path="/tea" element={<Tea />} />
              <Route path="/solarpanels" element={<SolarPanels />} />
              <Route path="/cold" element={<Cold />} />
              <Route path="/news" element={<News />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/partners" element={<Partners />} />
            </Routes>
          </div>

          <Footer />
        </div>
      </Router>
    </LanguageProvider>
  );
}

export default App;
