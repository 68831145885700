import React, { useContext } from 'react';
import './Tea.css';
import Navbar from '../components/Navbar';
import { LanguageContext } from '../context/LanguageContext';

// Example images (replace with your own paths)
import img1 from '../assets/images/Blueberry.jpg';
import img2 from '../assets/images/blueberryworth.jpg';
import img3 from '../assets/images/solarPanels.jpg';
import img4 from '../assets/images/Tea.jpg';

// Example icons for the produce row
import iconHazelnut from '../assets/icons/hazelnut_black.png';
import iconHoney from '../assets/icons/honey_black.png';
import iconSolar from '../assets/icons/solar-panels_black.png';
import iconTea from '../assets/icons/tea_black.png';
import iconBlueberry from '../assets/icons/berries_black.png';
import iconCold from '../assets/icons/cold_black.png';
import { Link } from 'react-router-dom';

function Tea() {
  const { t } = useContext(LanguageContext);

  return (
    <>
      <div>
        <Navbar />
        <div className="Tea-container"></div>

        <div className="Tea-content">
          <h1 className="Tea-title">{t.Tea.title}</h1>
          
          <div className="Tea-info">
            <div className="Tea-text">
              <h2>{t.Tea.sustainableAgriculture}</h2>
              <p>{t.Tea.sustainableAgricultureText}</p>

              <h2>{t.Tea.cultivationStrategy}</h2>
              <p>{t.Tea.cultivationStrategyText}</p>
            </div>

            <div className="Tea-images">
              <img src={img1} alt="Blueberry 1" />
              <img src={img2} alt="Blueberry 2" />
              <img src={img3} alt="Blueberry 3" />
              <img src={img4} alt="Blueberry 4" />
            </div>
          </div>
        </div>

        <div className="Tea-other-produces">
          <h3>{t.Tea.otherProducts}</h3>
          <div className="other-produces-list">
            <Link to='/blueberry' className="produce-item">
              <img src={iconBlueberry} alt="Blueberry" />
              <span>{t.Honey.blueberry} →</span>
            </Link>
            <Link to='/hazelnut' className="produce-item">
              <img src={iconHazelnut} alt="Hazelnut" />
              <span>{t.Tea.hazelnut} →</span>
            </Link>
            <Link to='/honey' className="produce-item">
              <img src={iconHoney} alt="Honey" />
              <span>{t.Tea.honey} →</span>
            </Link>
            <Link to='/solarpanels' className="produce-item">
              <img src={iconSolar} alt="Sun Panel" />
              <span>{t.Tea.sunPanel} →</span>
            </Link>
            <Link to='/cold' className="produce-item">
              <img src={iconCold} alt="iconCold" />
              <span>{t.Tea.cold} →</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tea;
