import React, { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import './HomeHero.css';
import Navbar from '../components/Navbar';
import MissionSection from '../components/MissionSection';
import ProduceSection from '../components/ProductSection';
import PartnersCarousel from '../components/PartnersCarousel';
import { Link } from 'react-router-dom';
import { SlArrowDown } from "react-icons/sl";

// Example icon imports
import blueberryIcon from '../assets/icons/Blueberry.png';
import teaIcon from '../assets/icons/Tea.png';
import hazelnutIcon from '../assets/icons/Hazelnut.png';
import honeyIcon from '../assets/icons/Honey.png';
import solarIcon from '../assets/icons/SolarPanels.png';
import coldStorageIcon from '../assets/icons/Coldstorage.png';

import blueberryIconSmall from '../assets/icons/berriesIconSmall.svg';
import hazelnutIconSmall from '../assets/icons/hazelnutIconSmall.svg';
import honeyIconSmall from '../assets/icons/honeyIconSmall.svg';
import teaIconSmall from '../assets/icons/teaIconSmall.svg';
import solarIconSmall from '../assets/icons/solarIconSmall.svg';
import coldStorageIconSmall from '../assets/icons/snowflakeIconSmall.svg';

import orchardBackground from '../assets/LandScapeBig.jpg';


import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


function Home() {
    const sliderSettings = {
    dots: true,          // Show navigation dots
    infinite: true,      // Loops the carousel
    speed: 500,          // Animation speed
    slidesToShow: 3,     // How many slides per view
    slidesToScroll: 1,   // How many slides to move at a time
    autoplay: true,      
    autoplaySpeed: 3000, // 3 seconds
    responsive: [
      {
        breakpoint: 768, // If screen width < 768px
        settings: {
          slidesToShow: 1, // Show only 1 slide at a time on mobile
        },
      },
    ],
  };

  const {t} = useContext(LanguageContext);

  return (
    <>
      <Navbar />
      <div className="home-container">
        <section 
          className="hero-section" 
          style={{ backgroundImage: `url(${orchardBackground})` }}
        >
          <div className="hero-overlay">
            <div className="hero-card">
              {/* LEFT: About us */}
              <div className="hero-about">
                <h2>{t.Home.aboutUsTitle}</h2>
                <p>
                  {t.Home.aboutUsText}
                </p>
              </div>
              {/* RIGHT: Icons */}
              <div className="hero-icons">
                <Link to='/blueberry' className="hero-icon">
                  <img src={blueberryIcon} alt="Blueberry" />
                  <span>{t.Home.icons.blueberry}</span>
                </Link>
                <Link to='/tea' className="hero-icon">
                  <img src={teaIcon} alt="Tea" />
                  <span>{t.Home.icons.tea}</span>
                </Link>
                <Link to='/Hazelnut' className="hero-icon">
                  <img src={hazelnutIcon} alt="Hazelnut" className='icon_hover' />
                  <span>{t.Home.icons.hazelnut}</span>
                </Link>
                <Link to='/Honey' className="hero-icon">
                  <img src={honeyIcon} alt="Honey" />
                  <span>{t.Home.icons.honey}</span>
                </Link>
                <Link to='/SolarPanels' className="hero-icon">
                  <img src={solarIcon} alt="Solar" />
                  <span>{t.Home.icons.solar}</span>
                </Link>
                <Link to='/Cold' className="hero-icon">
                  <img src={coldStorageIcon} alt="Cold Storage" />
                  <span>{t.Home.icons.cold}</span>
                </Link>
              </div>
            </div>
          </div>
          <div 
            className="scroll-down" 
            onClick={() => {
              const target = document.getElementById('main-content');
              if (target) {
                target.scrollIntoView({ behavior: 'smooth' });
              }
            }}
          >
            <span className="arrow"><SlArrowDown /></span>
          </div>
        </section>
        <div id="main-content" className="content-section">
          <MissionSection />
        </div>
        <div>
          <ProduceSection />
        </div>
        <div className="feature-cards-container">
          <div className="feature-card blueberry">
            <div className="feature-card-header">
              <h2 className="feature-card-title">{t.Home.icons.blueberry}</h2>
              <img className="feature-card-icon" src={blueberryIconSmall}/>
            </div>
            <div className="feature-card-content">
              <p className="feature-card-text">
                {t.Blueberry.sustainableAgricultureTextShort}
              </p>
              <Link to="/blueberry" className="feature-card-link">
                {t.Home.LearnMore}
                <span className="feature-card-arrow">→</span>
              </Link>
            </div>
          </div>
          <div className="feature-card hazelnut">
            <div className="feature-card-header">
              <h2 className="feature-card-title">{t.Home.icons.hazelnut}</h2>
              <img className="feature-card-icon" src={hazelnutIconSmall}/>
            </div>
            <div className="feature-card-content">
              <p className="feature-card-text">
                {t.Hazelnut.sustainableAgricultureTextShort}
              </p>
              <Link to="/Hazelnut" className="feature-card-link">
                {t.Home.LearnMore}
                <span className="feature-card-arrow">→</span>
              </Link>
            </div>
          </div>
          <div className="feature-card honey">
            <div className="feature-card-header">
              <h2 className="feature-card-title">{t.Home.icons.honey}</h2>
              <img className="feature-card-icon" src={honeyIconSmall}/>
            </div>
            <div className="feature-card-content">
              <p className="feature-card-text">
                {t.Honey.sustainableAgricultureTextShort}
              </p>
              <Link to="/honey" className="feature-card-link">
                {t.Home.LearnMore}
                <span className="feature-card-arrow">→</span>
              </Link>
            </div>
          </div>
          <div className="feature-card tea">
            <div className="feature-card-header">
              <h2 className="feature-card-title">{t.Home.icons.tea}</h2>
              <img className="feature-card-icon" src={teaIconSmall}/>
            </div>
            <div className="feature-card-content">
              <p className="feature-card-text">
                {t.Tea.sustainableAgricultureTextShort}
              </p>
              <Link to="/tea" className="feature-card-link">
                {t.Home.LearnMore}
                <span className="feature-card-arrow">→</span>
              </Link>
            </div>
          </div>
          <div className="feature-card solar">
            <div className="feature-card-header">
              <h2 className="feature-card-title">{t.Home.icons.solar}</h2>
              <img className="feature-card-icon" src={solarIconSmall}/>
            </div>
            <div className="feature-card-content">
              <p className="feature-card-text">
                {t.SolarPanels.sustainableAgricultureTextShort}
              </p>
              <Link to="/solarpanels" className="feature-card-link">
                {t.Home.LearnMore}
                <span className="feature-card-arrow">→</span>
              </Link>
            </div>
          </div>
          <div className="feature-card cold">
            <div className="feature-card-header">
              <h2 className="feature-card-title">{t.Home.icons.cold}</h2>
              <img className="feature-card-icon" src={coldStorageIconSmall}/>
            </div>
            <div className="feature-card-content">
              <p className="feature-card-text">
                {t.Cold.sustainableAgricultureTextShort}
              </p>
              <Link to="/cold" className="feature-card-link">
                {t.Home.LearnMore}
                <span className="feature-card-arrow">→</span>
              </Link>
            </div>
          </div>
        </div>
        

      </div>
    </>
  );
}

export default Home;