// src/pages/Gallery.js
import React, { useState, useEffect } from 'react';
import './Gallery.css';
import Navbar from '../components/Navbar';

function Gallery() {
  const [albums, setAlbums] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [visiblePictures, setVisiblePictures] = useState(8); // Number of initially visible pictures
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [showNavButtons, setShowNavButtons] = useState(true); // Changed to true by default
  
  // Check if device is mobile
  useEffect(() => {
    const checkIsMobile = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
    };
    
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
    
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);
  
  useEffect(() => {
    // Function to load images from the specific folder
    const loadImagesFromFolder = async () => {
      try {
        // This assumes you're using webpack or a similar bundler that supports this import pattern
        const imageContext = require.context('../assets/images/real', false, /\.(jpg|jpeg|png|gif)$/);
        const imageFiles = imageContext.keys();
        
        // Get the actual image paths
        const loadedImages = imageFiles.map(path => imageContext(path));
        
        // Generate albums based on naming conventions (example: album_name_cover.jpg)
        const albumCovers = imageFiles.filter(path => path.includes('_cover'));
        
        const generatedAlbums = albumCovers.map((coverPath, index) => {
          const albumName = coverPath.replace('./', '').split('_cover')[0].replace(/-/g, ' ');
          return {
            id: index + 1,
            title: albumName.charAt(0).toUpperCase() + albumName.slice(1) + '\n2025 Album',
            cover: imageContext(coverPath)
          };
        });
        
        setAlbums(generatedAlbums.length > 0 ? generatedAlbums : [
          {
            id: 1,
            title: 'Farm Gallery\n2025 Album 🍯',
            cover: loadedImages[0] || ''
          }
        ]);
        
        setPictures(loadedImages);
      } catch (error) {
        console.error('Error loading images:', error);
        // Set empty arrays if loading fails
        setAlbums([]);
        setPictures([]);
      }
    };
    
    loadImagesFromFolder();
  }, []);
  
  // Load more pictures based on device
  const loadMorePictures = () => {
    const increment = isMobile ? 4 : 8;
    setVisiblePictures(prev => prev + increment);
  };
  
  const openImageModal = (index) => {
    setCurrentImageIndex(index);
    setSelectedImage(pictures[index]);
    document.body.style.overflow = 'hidden'; // Prevent scrolling when modal is open
    
    // Always show navigation buttons when opening an image
    setShowNavButtons(true);
  };
  
  const closeImageModal = () => {
    setSelectedImage(null);
    document.body.style.overflow = ''; // Restore scrolling
  };
  
  const navigateImage = (direction) => {
    let newIndex = currentImageIndex + direction;
    
    // Loop back to the beginning or end if out of bounds
    if (newIndex < 0) {
      newIndex = pictures.length - 1;
    } else if (newIndex >= pictures.length) {
      newIndex = 0;
    }
    
    setCurrentImageIndex(newIndex);
    setSelectedImage(pictures[newIndex]);
  };
  
  // Toggle navigation buttons visibility (for mobile)
  const toggleNavButtons = (e) => {
    if (isMobile) {
      e.stopPropagation(); // Prevent closing the modal when tapping to toggle buttons
      setShowNavButtons(prev => !prev);
    }
  };
  
  // Handle touch events for swiping on mobile
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  
  // Minimum swipe distance (in px)
  const minSwipeDistance = 50;
  
  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };
  
  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };
  
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    if (isLeftSwipe) {
      navigateImage(1); // Next image
    } else if (isRightSwipe) {
      navigateImage(-1); // Previous image
    } else {
      // If it's a tap (not a swipe), toggle navigation buttons
      setShowNavButtons(prev => !prev);
    }
  };
  
  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!selectedImage) return;
      
      if (e.key === 'ArrowLeft') {
        navigateImage(-1);
      } else if (e.key === 'ArrowRight') {
        navigateImage(1);
      } else if (e.key === 'Escape') {
        closeImageModal();
      }
    };
    
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [selectedImage, currentImageIndex, pictures]);
  
  return (
    <>
    <div><Navbar /></div>
    <div className="gallery-container">
      {/* Heading & subtitle */}
      <h1 className="gallery-title">Gallery</h1>
      <p className="gallery-subtitle">Highlights from our journey.</p>

      {/* Albums section */}
      <h2 className="gallery-section-title">Albums</h2>
      <div className="gallery-albums">
        {albums.map(album => (
          <div className="album-card" key={album.id}>
            <div className="album-cover">
              <img src={album.cover} alt={album.title} />
            </div>
            <div className="album-info">
              <h3>{album.title}</h3>
              <button className="album-link">See pictures →</button>
            </div>
          </div>
        ))}
      </div>

      {/* Pictures grid */}
      <h2 className="gallery-section-title">Pictures</h2>
      <div className="gallery-pictures-grid">
        {pictures.slice(0, visiblePictures).map((pic, index) => (
          <div 
            className="picture-item" 
            key={index}
            onClick={() => openImageModal(index)}
          >
            <img src={pic} alt={`Gallery ${index + 1}`} />
          </div>
        ))}
      </div>

      {/* "More pictures" button - only show if there are more pictures to load */}
      {visiblePictures < pictures.length && (
        <div className="gallery-more">
          <button onClick={loadMorePictures}>More pictures</button>
        </div>
      )}
      
      {/* Image Modal */}
      {selectedImage && (
        <div className="image-modal-overlay" onClick={closeImageModal}>
          <div 
            className="image-modal-content" 
            onClick={(e) => e.stopPropagation()}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
          >
            {/* Always show navigation buttons regardless of device */}
            <button 
              className="modal-nav-button modal-nav-left" 
              onClick={(e) => {
                e.stopPropagation();
                navigateImage(-1);
              }}
              aria-label="Previous image"
            >
              &lt;
            </button>
            <button 
              className="modal-nav-button modal-nav-right" 
              onClick={(e) => {
                e.stopPropagation();
                navigateImage(1);
              }}
              aria-label="Next image"
            >
              &gt;
            </button>
            
            <div className="modal-image-container" onClick={toggleNavButtons}>
              <img 
                src={selectedImage} 
                alt={`Gallery item ${currentImageIndex + 1}`}
              />
              <div className="image-counter">{currentImageIndex + 1} / {pictures.length}</div>
            </div>
            
            {/* Regular close button - visible on desktop */}
            {!isMobile && (
              <button 
                className="modal-close-button" 
                onClick={closeImageModal}
                aria-label="Close image viewer"
              >
                ×
              </button>
            )}
            
            {/* Mobile close button - always visible on mobile */}
            {isMobile && (
              <button 
                className="mobile-close-button" 
                onClick={(e) => {
                  e.stopPropagation();
                  closeImageModal();
                }}
                aria-label="Close image viewer"
              >
                ×
              </button>
            )}
          </div>
        </div>
      )}
    </div>
    </>
  );
}

export default Gallery;