import React from 'react';
import './Footer.css';

// Import icons (assuming you're using react-icons)
import { MdEmail } from 'react-icons/md';
import { FaPhone } from 'react-icons/fa';
import { FaInstagram, FaFacebook } from 'react-icons/fa';

// You'll need to import your logo and bee image
import logo from '../assets/Logo.png'; // Update path as needed
import beeImage from '../assets/icons/icons/icon-bee.png'; // Update path as needed

function Footer() {
  return (
    <footer className="site-footer">
      <div className="footer-content">
        {/* Logo and name */}
        <div className="footer-brand">
          <img src={logo} alt="Seeder Logo" className="footer-logo" />
        </div>
        
        {/* Email section */}
        <div className="footer-contact">
          <div className="footer-contact-item">
            <MdEmail className="footer-icon" />
            <div className="contact-links">
              <a href="mailto:laituriagri@gmail.com">laituriagri@gmail.com</a>
              <a href="mailto:rkhargelia@gmail.com">rkhargelia@gmail.com</a>
            </div>
          </div>
        </div>
        
        {/* Phone section */}
        <div className="footer-contact">
          <div className="footer-contact-item">
            <FaPhone className="footer-icon" />
            <span>+995 599 177 774</span>
          </div>
        </div>
        
        {/* Social media links */}
        <div className="footer-social">
          <a href="https://www.facebook.com/profile.php?id=100083196437437" target="_blank" rel="noreferrer">
            <FaFacebook className="footer-social-icon" />
            <span>Facebook</span>
          </a>
        </div>
        
        {/* Bee illustration */}
        <div className="footer-bee-container">
          <img src={beeImage} alt="Bee" className="footer-bee" />
        </div>  
      </div>
      
      {/* Copyright text */}
      <div className="footer-copyright">
        <p>LTD "Seeder" 2025. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;