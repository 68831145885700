import React, { useContext } from 'react';
import './Honey.css';
import Navbar from '../components/Navbar';
import { LanguageContext } from '../context/LanguageContext';


import img1 from '../assets/images/Blueberry.jpg';
import img2 from '../assets/images/blueberryworth.jpg';
import img3 from '../assets/images/solarPanels.jpg';
import img4 from '../assets/images/Tea.jpg';

// Example icons for the produce row
import iconHazelnut from '../assets/icons/hazelnut_black.png';
import iconHoney from '../assets/icons/honey_black.png';
import iconSolar from '../assets/icons/solar-panels_black.png';
import iconTea from '../assets/icons/tea_black.png';
import iconBlueberry from '../assets/icons/berries_black.png';
import iconCold from '../assets/icons/cold_black.png';

import { Link } from 'react-router-dom';

function Honey() {
  const { t } = useContext(LanguageContext);

  return (
    <>
      <div>
        <Navbar />
        <div className="Honey-container"></div>

        <div className="Honey-content">
          <h1 className="Honey-title">{t.Honey.title}</h1>
          
          <div className="Honey-info">
            <div className="Honey-text">
              <h2>{t.Honey.sustainableAgriculture}</h2>
              <p>{t.Honey.sustainableAgricultureText}</p>

              <h2>{t.Hazelnut.cultivationStrategy}</h2>
              <p>{t.Honey.cultivationStrategyText}</p>
            </div>

            <div className="Honey-images">
              <img src={img1} alt="Blueberry 1" />
              <img src={img2} alt="Blueberry 2" />
              <img src={img3} alt="Blueberry 3" />
              <img src={img4} alt="Blueberry 4" />
            </div>
          </div>
        </div>

        <div className="Honey-other-produces">
          <h3>{t.Honey.otherProducts}</h3>
          <div className="other-produces-list">
            <Link to='/blueberry' className="produce-item">
              <img src={iconBlueberry} alt="BlueBerry" />
              <span>{t.Honey.blueberry} →</span>
            </Link>
            <Link to='/hazelnut' className="produce-item">
              <img src={iconHazelnut} alt="Hazelnut" />
              <span>{t.Honey.hazelnut} →</span>
            </Link>
            <Link to='/solarpanels' className="produce-item">
              <img src={iconSolar} alt="Sun Panel" />
              <span>{t.Honey.sunPanel} →</span>
            </Link>
            <Link to='/tea' className="produce-item">
              <img src={iconTea} alt="Tea" />
              <span>{t.Honey.tea} →</span>
            </Link>
            <Link to='/cold' className="produce-item">
              <img src={iconCold} alt="Cold icon" />
              <span>{t.Honey.cold} →</span>
            </Link>
            
          </div>
        </div>
      </div>
    </>
  );
}

export default Honey;
