import React, { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import './partners.css';
import Navbar from '../components/Navbar';


const PartnersSection = () => {
    const {t} = useContext(LanguageContext);
  return (
    <>
    <Navbar />
    
    <div className="partners-container">
      <h1 className="partners-title">{t.partners.title}</h1>
      <p className="partners-subtitle">
        We're proud to partner with trusted companies who share our values of quality and innovation.
      </p>
      
      <div className="partners-grid">
        {t.partners.partners.map(partner => (
          <div key={partner.id} className="partner-card">
            <div className="logo-placeholder">LOGO</div>
            <a href={partner.link} className="see-more-link">
              See more <span className="arrow">→</span>
            </a>
          </div>
        ))}
      </div>
    </div>
    </>
    
  );
};

export default PartnersSection;